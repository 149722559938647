import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const BioPage = () => {
	return (
		<Layout>
			<Seo title="Biography" />
			<h2>Biography</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/Jesse-high-5.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>
			<h3>Bio: Jul 2023</h3>
			<p>
				Jesse Rivest is a singer-songwriter and guitarist who you likely have never heard of.
				And yet, he's been around for over 18 years...
				He's been <span className="bold">around</span>.
				Originally from Stony Plain, Alberta, Rivest justifiably calls Kelowna, British Columbia, home.
				(Ah, but he was born on the Bruce Peninsula in Ontario.)
				He released his first LP in Calgary, Alberta, after travelling and performing around Australia for most of 2005;
				he had lived in Calgary for a few years up to that point.
				His next release was an EP made while living in New Zealand for seven years;
				most of 2011 was spent in Buenos Aires, Argentina;
				his next EP—and various singles after that—were produced in Brasilia, the capital of Brazil, where he's been split-based (Kelowna/Brasilia) since 2013.
				No wonder you haven't heard of him—<span className="bold">he hasn't been around</span>.
			</p>
			<p>
				It's hard to build roots when one wanders around so.
				One of Rivest's early tunes was selected by the Calgary Folk Music Festival's 2005 songwriting contest as a performing finalist,
				but that news simply fluttered in the vapour trails—Rivest was already in Australia.
				Unbeknownst to him, his first LP was gaining 132K streams on Pandora Radio while he was abroad.
				Radio New Zealand aired nationally a 40-minute interview with him shortly after he got settled in Wellington in 2007;
				he performed a lot there over many years while he called New Zealand home.
				However, adventure and curiousity lead him to start over in South America.
				While studying music in Brasilia for four years, he produced an indie-rock EP with some colleagues and soft-released it at the beginning of the COVID-19 pandemic.
				During the pandemic, he switched to producing singles—a couple of which were equal collaborations with Tico de Moraes, a jazz-pop singer-songwriter in Brasilia.
				Their second collaboration, <span className="italic">Wonderful Words</span>, was included on Spotify's Vocal Jazz editorial playlist for 11 months.
				Although stretching himself thin all these years, Rivest has left some lingering impressions and gained many friendships and experiences along his cosmopolitan journey.
			</p>
			<p>
				Rivest considers himself a late bloomer and a lifelong learner.
				Through writing, producing, and performing his songs, all the while trying to leave light footprints as he encounters the world,
				he has grown to embody what he values.
				He has deliberately effected resonant change in his own life, and on a good day he can smile knowing he's getting closer to what he holds ideal.
			</p>
			<p>
				Follow Jesse Rivest's music at <Link to="/music/">www.jesserivest.com/music/</Link>.<br />
				File under: indie folk, contemporary singer-songwriter, Canadian
			</p>
		</Layout>
	);
}

export default BioPage;
